import { render, staticRenderFns } from "./AirQualityAreas.vue?vue&type=template&id=520ae208&scoped=true&"
import script from "./AirQualityAreas.vue?vue&type=script&lang=ts&"
export * from "./AirQualityAreas.vue?vue&type=script&lang=ts&"
import style0 from "./AirQualityAreas.vue?vue&type=style&index=0&id=520ae208&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "520ae208",
  null
  
)

/* custom blocks */
import block0 from "./AirQualityAreas.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QIcon,QBtn,QInnerLoading});
