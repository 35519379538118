

























































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import date from 'quasar/src/utils/date.js';;
import CCToggle from '../components/CCToggle.vue';
import { SocketIOMixin } from '../io-utils';
import { apiGet } from '../store';


@Component({
  components: {
    CCToggle,
  },
})
export default class AirQualityAreas extends Mixins(SocketIOMixin) {
  public $store: any;
  public viewType: string = 'cards';
  public areasQai: any = {};
  public areaFilter: string = '';
  public areas: any = [];
  public areasWithAlerts: boolean = false;
  public areasListWithAlerts: any = [];
  public areasLastUpdate: any = {};
  public loading: boolean = false;

  public created() {
    this.viewType = localStorage.getItem('dbAreaViewType') || 'cards';
    this.areasWithAlerts = localStorage.getItem('dbAreasWithAlerts') === 'on';
    this.loading = true;
    this.refresh();
  }

  public refresh() {
    this.loadAreasQai();
  }

  public mounted() {
    const areas = this.$store.getters.getAreas();
    const socket = this.ioListen('/qai-area');
    socket.once('connect', (message: any) => {
      for (const area of areas) {
        socket.emit('area', area.id);
      }
    })
    .on('indicators', (message: any) => {
      this.updateFromResponse(message);
    });
  }

  public beforeDestroy() {
    this.ioNamespace('/qai-area').off('indicators');
    this.ioClose();
  }

  private updateFromResponse(response: any) {
    const areaId = response.area_id;
    if (response.data.time === null) {
      this.areasQai[areaId] = 3;
    } else {
      this.areasQai[areaId] = response.data.data.qai;
    }
  }

  @Watch('areasWithAlerts')
  private saveAreasWithAlerts() {
    localStorage.setItem('dbAreasWithAlerts', this.areasWithAlerts ? 'on' : 'off');
  }

  private loadAreasQai() {
    apiGet(`/areas/qai`)
    .then((response: any) => {

      const areasListWithAlerts: any = [];
      const areasLastUpdate = {};
      const areasQai = {};

      for (const entry of Object.entries(response.qais)) {
        const areaId = parseInt(entry[0], 10);
        const value: any = entry[1];
        areasLastUpdate[areaId] = value['last_updated_at'];
        areasQai[areaId] = value['qai'];
        if (this.isOffline(areasLastUpdate[areaId])) {
          areasQai[areaId] = -1;
        } else if (areasQai[areaId] > 0) {
          areasListWithAlerts.push(areaId);
        }
      }

      this.areasListWithAlerts = areasListWithAlerts;
      this.areasLastUpdate = areasLastUpdate;
      this.areasQai = areasQai;

      this.loading = false;
    })
    .catch((error: any) => {
      this.globalError(error);
    });

  }

  private setViewType(vt: string) {
    this.viewType = vt;
    localStorage.setItem('dbAreaViewType', vt);
  }

  get areasList() {
    let areas = this.$store.getters.getAreas();
    if (this.areaFilter) {
      const pattern: string = this.areaFilter.toLowerCase();
      areas = areas.filter((area: any) => {
        return area.name.toLowerCase().indexOf(pattern) >= 0;
      });
    }
    if (this.areasWithAlerts) {
      areas = areas.filter((area: any) => this.areasListWithAlerts.includes(area.id));
    }
    return areas.sort((a: any, b: any) => {
      const onA: boolean = this.isAreaOffline(a);
      const onB: boolean = this.isAreaOffline(b);
      if (onA === onB) {
        return a.name.localeCompare(b.name);
      } else {
        if (onA) { return 1; }
        return -1;
      }
    });
  }

  get cardCss() {
    if (this.$q.screen.lt.sm && this.$q.screen.width > 300) {
     return 'col-12';
    }
    if (this.$q.screen.width < 800) {
      return 'col-6';
    } else if (this.$q.screen.md) {
      return 'col-4';
    } else if (this.$q.screen.lg) {
      return 'col-3';
    } else if (this.$q.screen.xl) {
      return 'col-2';
    }
    return 'col-6';
  }

  private getAreaTimeDiff(dt: string) {
    const d1 = new Date();
    const d2 = Date.parse(dt);
    const diff = date.getDateDiff(d1, d2, 'minutes');
    return diff;
  }

  private isOffline(dt?: string) {
    if (dt === undefined || dt === null) {
      return true;
    }
    const timediff = this.getAreaTimeDiff(dt);
    if (timediff === -1) {
      return true;
    }
    return timediff >= 60;
  }

  private isAreaOffline(area: any) {
    if (area === undefined) {
      return true;
    }
    return this.isOffline(this.areasLastUpdate[area.id]);
  }

  private getBuildingIds() {
    return this.$store.state.buildings.map((building: any) => building.id);
  }

  private modFix(v: number, n: number) {
    return ((v % n) + n) % n;
  }

}
